import React from 'react';

import { BaseTemplate } from '@templates';
import { SEO } from '@components';

import './index.scss';

import DevelopersHeroSection from './sections/developers_hero_section/DevelopersHeroSection';
import IntegrationsSection from './sections/integrations_section/IntegrationsSection';

const IndexPage = () => (
  <BaseTemplate className="subpage">
    <SEO title="Developers" />
    <div className="subpage-developers">
      <DevelopersHeroSection />
      <IntegrationsSection />
    </div>
  </BaseTemplate>
);

export default IndexPage;
