import React from 'react';
import { Link } from 'gatsby';
import { Button, PageSection } from '@components';

import devIntegration from '@images/developers-main-image.png';

import './DevelopersHeroSection.scss';

const DevelopersHeroSection = () => (
  <PageSection className="section-hero-developer">
    <div className="container">
      <div className="columns is-vcentered is-centered">
        <div className="hero-header column">
          <h1 className="text-sh050 text-2xl-is-mobile text-3xl-from-tablet text-left-from-tablet">
            Best developer APIs to build modern payment experiences
          </h1>
        </div>
        <div className="column">
          <div className="hero-image-container">
            <img src={devIntegration} alt="placeholder" className="hero-image" />
          </div>
        </div>
      </div>
      <div className="hero-content">
        <p className="text-md-is-mobile text-lg-from-tablet text-sh800 mx-auto">
          PayMongo APIs are the fastest and easiest way to integrate payments into your projects,
          everything from small side gigs to complex products and services for millions of
          customers.
        </p>
        <Button
          className="button-cta display-block mx-auto"
          buttonLabel="Get started for free"
          destination={process.env.GATSBY_LINK_TO_SIGNUP}
        />
        <a href={process.env.GATSBY_LINK_TO_DEVDOCS} className="link">
          Check our developer documentation
        </a>
      </div>
    </div>
  </PageSection>
);

export default DevelopersHeroSection;
