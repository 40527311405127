import React from 'react';
import { string } from 'prop-types';

import './IntegrationListItem.scss';

const IntegrationListItem = ({ image, title, description }) => {
  return (
    <li className="integrations-list-item">
      <div className="list-item-container is-centered is-vcentered">
        <div className="integrations-image-container">
          <img src={image} alt="placeholder" className="integrations-image" />
        </div>
        <div className="integrations-content">
          <h3 className="text-md text-medium text-gr700">{title}</h3>
          <p className="text-xs text-sh800 my-none">{description}</p>
        </div>
      </div>
    </li>
  );
};

IntegrationListItem.propTypes = {
  image: string.isRequired,
  title: string.isRequired,
  description: string.isRequired
};

export default IntegrationListItem;
