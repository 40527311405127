import React, { useState } from 'react';
import { Button, PageSection, HubspotFormModal, PaymongoElement } from '@components';

import { Helmet } from 'react-helmet';

import '@style/base.scss';
import '@style/grid.scss';
import secured from '@images/icon-secured.png';
import accept from '@images/icon-accept.png';
import anyPlatform from '@images/icon-any-platform.png';
import devFirst from '@images/icon-dev-first.png';

import './IntegrationsSection.scss';
import IntegrationListItem from './components/integration-list-item/IntegrationListItem';

const data = [
  {
    image: accept,
    title: 'Build a seamless payments experience',
    description:
      'Give your customers an elegant and delightful way to pay, build higher confidence and, in turn, increase your conversions. Yes, we hate pesky redirects too!'
  },
  {
    image: secured,
    title: 'Stay secure and compliant',
    description:
      'Our combination of advanced fraud detection tools and PCI-certified systems ensure that you remain compliant with regulations and protect you from any fraudulent transactions.'
  },
  {
    image: anyPlatform,
    title: 'Accept payments on any platform',
    description:
      'Every business has different payment needs. Our tools are built to standard so that you can get up and running on any technology of your choosing.'
  },
  {
    image: devFirst,
    title: 'Developers first',
    description:
      'We believe that finance is a problem rooted in code. As developers ourselves, we build elegant abstractions so you can seamlessly integrate payments and focus on what you do best.'
  }
];

// WILL BE USED IN FUTURE

// const partnerLogos = [
//   { image: placeholderImage },
//   { image: placeholderImage },
//   { image: placeholderImage },
//   { image: placeholderImage }
// ];

const IntegrationsSection = () => {
  const integrations = data.map((integration, index) => (
    <IntegrationListItem
      key={`integration-item-${index + 1}`}
      image={integration.image}
      title={integration.title}
      description={integration.description}
    />
  ));

  const [scheduleModalVisible, setScheduleModalVisible] = useState(false);
  const toggleScheduleModalVisible = () => setScheduleModalVisible(!scheduleModalVisible);

  // WILL BE USED IN FUTURE
  // const partners = partnerLogos.map((logo, index) => (
  //   <li key={`partner-logo-${index + 1}`} className="column is-narrow-mobile">
  //     <div className="partner-logo-container">
  //       <img src={logo.image} alt="" className="partner-logo" />
  //     </div>
  //   </li>
  // ));

  return (
    <>
      <Helmet>
        <script
          src="https://code.jquery.com/jquery-3.3.1.min.js"
          integrity="sha256-FgpCb/KJQlLNfOu91ta32o/NMZxltwRo8QtmkMRdAu8="
          crossOrigin="anonymous"
        />
      </Helmet>
      <PageSection className="integrations-section">
        <div className="container">
          <h2 className="text-gr800 text-2xl">Payments on modern standards</h2>
          <div className="integrations-list-container">
            <ul className="integrations-list">{integrations}</ul>
          </div>
          {/* <div className="integrations-partners-container">
            <ul className="partners-list columns is-centered is-vcentered is-mobile is-multiline">
              {partners}
            </ul>
          </div> */}
          <div className="integrations-application-content">
            <h3 className="application-header text-md text-sh800">
              Help bring more businesses online and shape the future of how we do commerce on the
              internet. Let’s build the internet economy together!
            </h3>
            <PaymongoElement
              name="Button"
              props={{
                className: 'link application-button outline  display-block centered',
                onClick: toggleScheduleModalVisible
              }}
            >
              Apply as a Developer Partner
            </PaymongoElement>
          </div>
        </div>
      </PageSection>
      <HubspotFormModal
        header="Apply for a Developer Partnership"
        visible={scheduleModalVisible}
        portalId="7079113"
        formId="e48ce54c-bb43-4504-a4fc-a736949225ad"
        onClose={toggleScheduleModalVisible}
        successMessage="Thank you for your application! We'll get back to you shortly."
      />
    </>
  );
};
export default IntegrationsSection;
